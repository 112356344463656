import React, { useEffect, useState } from 'react'
import styles from './BoostVotingPower.module.css'
import BrightId from './brightId/brightId'
import RegistriesProgress from '../../../molecules/RegistriesProgress'
import Section from '../../../molecules/Section'
import VoteDelecations from '../../../molecules/VoteDelegations'
import { useWeb3 } from '../../../../hooks/useWeb3'
import { getDelegations, getVoteScore } from './utils'

export default function BoostVotingPower(props: any) {
  const [currentBoost, setCurrentBoost] = useState<number>()
  const [maximumBoost, setMaximumBoost] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [delegates, setDelegates] = useState([])
  const [scores, setScores] = useState(undefined)
  const [error, setError] = useState(undefined)

  const { walletAddress, isRightNetwork, network, web3 } = useWeb3()

  const delegationsQuery = `
  query{
    delegations(where: {delegate_in: ["${walletAddress}"]}) {
      id
      delegator
      space
      delegate
    }
  }
`

  const changeCurrentBoost = (boost: number) => {
    setCurrentBoost(boost)
  }

  const calculateMaximumBoost = () => {
    var boost = 0
    for (const registry in props.registries) {
      boost += Number(props.registries[registry].boost) / 100
    }
    setMaximumBoost(boost)
  }

  useEffect(() => {
    if (!props.registries) return
    calculateMaximumBoost()
  }, [props.registries])

  useEffect(() => {
    if (!walletAddress || !isRightNetwork) {
      if (delegates.length !== 0) {
        setDelegates([])
        setScores(undefined)
      }
      return
    }
    getDelegations(delegationsQuery).then((resp) => {
      const walletAddresses = [walletAddress]
      resp.forEach((delegate: any) => {
        walletAddresses.push(delegate.delegator)
      })
      setDelegates(resp)
      getVoteScore(walletAddresses, network, web3)
        .then((resp) => {
          setScores(resp.data.result.scores[0])
        })
        .catch((error) => setError(error))

      setIsLoading(false)
    })
  }, [walletAddress, isRightNetwork])

  return (
    <Section
      id={props.id}
      title={props.title}
      headerCenter
      description={props.description}
    >
      <div className={styles.container}>
        <RegistriesProgress
          currentBoost={currentBoost}
          maximumBoost={maximumBoost}
          error={error}
          scores={scores}
          votingPowerData={props.votingInformation.votingPower}
          votingBoostData={props.votingInformation.votingBoost}
          delegates={delegates}
          isLoading={isLoading}
        />
        <VoteDelecations
          currentBoost={currentBoost}
          votingPowerData={props.votingInformation.votingPower}
          delegates={delegates}
          scores={scores}
          walletIcon={props.walletIcon}
        />
        <div className={styles.registries}>
          {props.registries && (
            <BrightId
              registry={props.registries.brightId}
              changeCurrentBoost={changeCurrentBoost}
            />
          )}
        </div>
      </div>
    </Section>
  )
}
