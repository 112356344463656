import React from 'react'
import Logo from '../../../atoms/Logo'
import styles from './Graphic.module.css'

export default function Graphic({
  content
}: {
  content: { title: string; text: string }[]
}) {
  return (
    <div className={styles.graphic}>
      {content.map((item) => (
        <div className={styles.item} key={item.title}>
          <h4>{item.title}</h4>
          <p>{item.text}</p>
        </div>
      ))}
      <div className={styles.ocean}>
        <Logo />
      </div>
    </div>
  )
}
