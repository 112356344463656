import React from 'react'
import Button from '../atoms/Button'
import styles from './RegistryCard.module.css'
import Img from 'gatsby-image'
import { RequestStatus } from '../pages/dao/web3Tools/common'
import QRCode from 'react-qr-code'
import { useWeb3 } from '../../hooks/useWeb3'
import { markdownify } from '../../utils'

export interface RegistryProps {
  name: string
  logo?: any
  description: string
  isVerified: boolean
  register: () => void
  weight?: number
  boost: number
  message: string
  requestStatus: string
  disabled: boolean
  showQRcode?: boolean
  buttonText: string
  onHideQRcode?: () => {}
}

export default function RegistryCard({
  description,
  isVerified,
  logo,
  register,
  boost,
  message,
  requestStatus,
  disabled,
  showQRcode,
  buttonText,
  onHideQRcode
}: RegistryProps) {
  const { walletAddress } = useWeb3()

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {logo?.childImageSharp?.fluid && (
          <Img className={styles.image} fluid={logo.childImageSharp.fluid} />
        )}
      </div>
      <div className={styles.description}>{markdownify(description)}</div>
      <div className={styles.actions}>
        {showQRcode ? (
          <div className={styles.qrCodeContainer}>
            <QRCode
              value={`brightid://link-verification/http:%2f%2fnode.brightid.org/OceanDAO/${walletAddress}`}
            />
            <Button
              size="small"
              style="ghost"
              onClick={() => {
                onHideQRcode()
              }}
              className={styles.qrCodeButton}
            >
              Done
            </Button>
          </div>
        ) : (
          <Button
            size="small"
            style="ghost"
            onClick={() => {
              register()
            }}
            disabled={disabled || requestStatus === RequestStatus.Pending}
          >
            {requestStatus === RequestStatus.Pending
              ? 'Loading...'
              : isVerified
              ? 'Claimed'
              : buttonText}
          </Button>
        )}
        {message && requestStatus !== RequestStatus.Pending && (
          <p
            className={
              requestStatus === RequestStatus.Success
                ? styles.success
                : styles.error
            }
          >
            {message}
          </p>
        )}
      </div>
    </div>
  )
}
