import ProgressBar from '@ramonak/react-progress-bar'
import React from 'react'
import styles from './CustomProgressBar.module.css'

interface ProgressBarProps {
  completed: number
}

export default function CustomProgressBar({
  completed,
  ...props
}: ProgressBarProps) {
  return (
    <>
      <ProgressBar
        completed={completed}
        className={styles.votingBoostBar}
        labelClassName={styles.progressBarLabel}
        barContainerClassName={styles.progressBarContainer}
      />
      <span>{`${completed}/400%`}</span>
    </>
  )
}
