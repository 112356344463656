import React, { useEffect, useState } from 'react'
import styles from './VoteDelegations.module.css'
import NumberUnit from './NumberUnit'
import { useWeb3 } from '../../hooks/useWeb3'
import InputWithButton from './InputWithButton'
import { markdownify } from '../../utils'

export default function VoteDelecations({
  votingPowerData,
  scores,
  delegates,
  walletIcon
}: {
  currentBoost: number
  votingPowerData: any
  scores: any
  delegates: any[]
  walletIcon: any
}) {
  const { walletAddress } = useWeb3()
  const [delegatedVotingPower, setDelegatedVotingPower] = useState('0')

  useEffect(() => {
    if (!scores) return
    let newDelegatedPower = 0
    delegates.forEach((delegator) => {
      if (scores[delegator.delegator]) {
        newDelegatedPower += scores[delegator.delegator]
      }
    })
    setDelegatedVotingPower(newDelegatedPower.toString())
  }, [scores])

  return (
    <div className={styles.registryContainer}>
      {delegates.length > 0 ? (
        <>
          <div className={styles.votingPower}>
            <span className={styles.sectionTitle}>
              {votingPowerData.powerDetailsText}
            </span>
            <div className={styles.votingPowerNumbers}>
              <NumberUnit
                label={votingPowerData.yourPowerText}
                value={
                  scores ? parseInt(scores[walletAddress]).toFixed(2) : '0.00'
                }
              />
              <NumberUnit
                label={votingPowerData.delegatedPowerText}
                value={
                  parseInt(delegatedVotingPower) > 0
                    ? parseInt(delegatedVotingPower).toFixed(2)
                    : '0.00'
                }
              />
            </div>
          </div>
          <div className={styles.votingBoost}>
            <span className={styles.sectionTitle}>
              {votingPowerData.delegatesText} <b>{delegates.length}</b>
            </span>
            <div className={styles.delegatesListContainer}>
              {delegates.map((delegate, key) => (
                <div key={key} className={styles.delegateContainer}>
                  <InputWithButton
                    className={styles.inputWithButton}
                    input={{
                      value: `${delegate.delegator.substr(
                        0,
                        5
                      )}...${delegate.delegator.substr(
                        delegate.delegator.length - 5
                      )}`,
                      type: 'text',
                      name: 'walletAddress',
                      disabled: true,
                      onChange: (value: string) => {},
                      placeholder: undefined
                    }}
                    button={{
                      text: scores
                        ? parseInt(scores[delegate.delegator]).toFixed(2)
                        : '0.00',
                      disabled: !walletAddress,
                      handleOnClick: () => {}
                    }}
                    image={walletIcon}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className={styles.noDelegatesContainer}>
          <p className={styles.title}>No delegates found</p>
          <div className={styles.description}>
            {markdownify(votingPowerData.delegatesDescription)}
          </div>
        </div>
      )}
    </div>
  )
}
