import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
import { strategies } from '../../../pages/dao/web3Tools/common'
import axios from 'axios'

export async function getDelegations(delegationsQuery: any) {
  const client = new ApolloClient({
    uri: 'https://api.thegraph.com/subgraphs/name/snapshot-labs/snapshot',
    cache: new InMemoryCache()
  })
  const delegations = await client.query({
    query: gql(delegationsQuery)
  })
  return delegations.data.delegations
}

export async function getVoteScore(
  walletAdresses: any[],
  network: string,
  web3: any
) {
  const snapshot = await web3.eth.getBlockNumber()
  const body = {
    params: {
      space: '',
      network: network.toString(),
      snapshot: snapshot,
      strategies: strategies,
      addresses: walletAdresses
    }
  }
  const headers = {
    'Content-Type': 'application/json'
  }
  const result = await axios.post(
    'https://score.snapshot.org/api/scores',
    body,
    {
      headers: headers
    }
  )
  return result
}
