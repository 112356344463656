import React from 'react'
import Button from '../atoms/Button'
import InputElement from '../atoms/Input/InputElement'
import styles from './InputWithButton.module.css'
import Img from 'gatsby-image'

export interface InputProps {
  placeholder: string
  value: string
  type: string
  name: string
  disabled: boolean
  onChange: (value: any) => void
}

export interface ButtonProps {
  text: string
  disabled: boolean
  handleOnClick: (value: any) => void
}

export default function InputWithButton({
  input,
  button,
  image,
  className
}: {
  input: InputProps
  button: ButtonProps
  image?: any
  className?: string
}) {
  return (
    <div className={`${styles.container} ${className}`}>
      {image?.childImageSharp?.fluid && (
        <Img fluid={image.childImageSharp.fluid} className={styles.icon} />
      )}
      <InputElement
        type={input.type}
        name={input.name}
        value={input.value}
        placeholder={input.placeholder}
        disabled={input.disabled}
        className={styles.input}
        onChange={(value: any) => input.onChange(value)}
      />
      <Button
        style="text"
        onClick={button.handleOnClick}
        className={styles.button}
        disabled={button.disabled}
      >
        {button.text}
      </Button>
    </div>
  )
}
