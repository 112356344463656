import React from 'react'
import Section from '../../../molecules/Section'
import styles from './SectionWalletConnection.module.css'
import Button from '../../../atoms/Button'
import { useWeb3 } from '../../../../hooks/useWeb3'
import InputWithButton from '../../../molecules/InputWithButton'

export default function SectionWalletConnection({
  walletIcon
}: {
  walletIcon?: any
}) {
  const {
    walletAddress,
    isRightNetwork,
    switchToSupportedNetwork,
    disconnect,
    connect
  } = useWeb3()

  return (
    <Section className={styles.walletConnectionCointainer}>
      <div className={styles.walletConnectionContent}>
        <span className={styles.walletAddressLabel}>
          {walletAddress ? 'Your wallet address' : 'Connect your wallet'}
        </span>
        {walletAddress ? (
          <>
            <InputWithButton
              input={{
                value: walletAddress,
                type: 'text',
                name: 'walletAddress',
                disabled: true,
                onChange: (value: string) => {},
                placeholder: undefined
              }}
              button={{
                text: 'disconnect',
                disabled: !walletAddress,
                handleOnClick: () => disconnect()
              }}
              image={walletIcon}
            />
            {isRightNetwork || (
              <>
                <span className={styles.warningMessage}>
                  Your wallet is connected to the wrong network.
                </span>
                <div className={styles.buttonsContainer}>
                  <Button
                    size="small"
                    style="ghost"
                    onClick={async () => {
                      await switchToSupportedNetwork()
                    }}
                  >
                    Switch to mainnet
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <Button size="small" style="primary" onClick={connect}>
            Connect Wallet
          </Button>
        )}
      </div>
    </Section>
  )
}
