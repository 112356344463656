import React, {
  useContext,
  useState,
  createContext,
  useEffect,
  ReactNode
} from 'react'
import Web3 from 'web3'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'

const SUPPORTED_CHAIN_ID_HEX = '0x1'
const SUPPORTED_CHAIN_ID = 1

const providerOption = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: '2da83d228268471abb4e85b273291497'
    }
  }
}

/* eslint-enable no-unused-vars */
interface ConsentProviderValue {
  walletAddress: string
  web3: Web3
  provider: any
  network: string
  isRightNetwork: boolean
  connect: () => void
  disconnect: () => void
  switchToSupportedNetwork: () => void
}

const ConsentContext = createContext({} as ConsentProviderValue)

function Web3Provider({ children }: { children: ReactNode }) {
  const [walletAddress, setWalletAddress] = useState(undefined)
  const [web3, setWeb3] = useState(undefined)
  const [network, setNetwork] = useState(undefined)
  const [provider, setProvider] = useState(undefined)
  const [isRightNetwork, setIsRightNetwork] = useState(true)
  const [web3Modal, setWeb3Modal] = useState(undefined)

  useEffect(() => {
    if (!web3) return
    web3.eth.net.getId().then((resp: number) => {
      setNetwork(resp)
    })
    const newAccount = web3.currentProvider.selectedAddress
      ? web3.currentProvider.selectedAddress
      : web3.currentProvider.accounts[0]
    setWalletAddress(newAccount)
  }, [web3])

  useEffect(() => {
    const isConnectedToRightNetwork = network === SUPPORTED_CHAIN_ID
    setIsRightNetwork(isConnectedToRightNetwork)
  }, [network])

  async function disconnect() {
    await web3Modal.clearCachedProvider()
    await setWalletAddress(undefined)
    if (!provider || web3.currentProvider.selectedAddress) return
    await provider.disconnect()
  }

  async function setProviderAndWeb3(provider: any) {
    setProvider(provider)
    provider.on('accountsChanged', (accounts: string) => {
      setWalletAddress(accounts[0])
    })
    provider.on('chainChanged', (chainId: string) => {
      setNetwork(parseInt(chainId, 16))
    })
    provider.on('disconnect', (code: number, reason: string) => {
      disconnect()
    })
    setWeb3(new Web3(provider))
  }

  async function connect() {
    const provider = await web3Modal.connect()
    setProviderAndWeb3(provider)
  }

  async function connectFromLocalStorage() {
    const newWeb3Modal = new Web3Modal({
      network: 'mainnet', // optional
      cacheProvider: true, // optional
      providerOptions: providerOption, // required
      theme: {
        background: 'rgb(39, 49, 56)',
        main: 'rgb(199, 199, 199)',
        secondary: 'rgb(136, 136, 136)',
        border: 'rgba(195, 195, 195, 0.14)',
        hover: 'rgb(16, 26, 32)'
      }
    })
    await setWeb3Modal(newWeb3Modal)
    const localStorageProvider = JSON.parse(
      localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER')
    )
    if (!localStorageProvider) return
    const provider = await newWeb3Modal.connectTo(localStorageProvider)
    setProviderAndWeb3(provider)
  }

  useEffect(() => {
    connectFromLocalStorage()
  }, [])

  const switchToSupportedNetwork = () => {
    provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: SUPPORTED_CHAIN_ID_HEX }]
    })
  }

  return (
    <ConsentContext.Provider
      value={
        {
          walletAddress,
          web3,
          network,
          provider,
          isRightNetwork,
          connect,
          disconnect,
          switchToSupportedNetwork
        } as ConsentProviderValue
      }
    >
      {children}
    </ConsentContext.Provider>
  )
}

const useWeb3 = (): ConsentProviderValue => useContext(ConsentContext)

export { Web3Provider, useWeb3, ConsentProviderValue }
export default Web3Provider
