import { brightIdContractAddress } from './brightId/contract'

/* eslint-disable no-unused-vars */
export enum RequestStatus {
  Success = 'SUCCESS',
  Error = 'ERROR',
  Pending = 'PENDING'
}

export const strategies = [
  {
    __typename: 'Strategy',
    name: 'ocean-dao-brightid',
    params: {
      brightIdMultiplier: 4,
      notVerifiedMultiplier: 1,
      brightIdNetwork: '1',
      registry: brightIdContractAddress,
      symbol: 'OCEAN',
      strategies: {
        '1': [
          {
            name: 'erc20-balance-of',
            params: {
              symbol: 'OCEAN',
              address: '0x967da4048cd07ab37855c090aaf366e4ce1b9f48',
              decimals: 18
            }
          },
          {
            name: 'ocean-marketplace',
            params: {
              symbol: 'OCEAN',
              address: '0x967da4048cD07aB37855c090aAF366e4ce1b9F48',
              decimals: 18
            }
          },
          {
            name: 'sushiswap',
            params: {
              symbol: 'OCEAN',
              address: '0x967da4048cD07aB37855c090aAF366e4ce1b9F48',
              decimals: 18
            }
          },
          {
            name: 'uniswap',
            params: {
              symbol: 'OCEAN',
              address: '0x967da4048cD07aB37855c090aAF366e4ce1b9F48',
              decimals: 18
            }
          },
          {
            name: 'contract-call',
            params: {
              address: '0x9712Bb50DC6Efb8a3d7D12cEA500a50967d2d471',
              args: [
                '%{address}',
                '0xCDfF066eDf8a770E9b6A7aE12F7CFD3DbA0011B5',
                '0x967da4048cD07aB37855c090aAF366e4ce1b9F48'
              ],
              decimals: 18,
              symbol: 'OCEAN',
              methodABI: {
                inputs: [
                  {
                    internalType: 'address',
                    name: 'provider',
                    type: 'address'
                  },
                  {
                    internalType: 'address',
                    name: 'poolToken',
                    type: 'address'
                  },
                  {
                    internalType: 'address',
                    name: 'reserveToken',
                    type: 'address'
                  }
                ],
                name: 'totalProviderAmount',
                outputs: [
                  {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                  }
                ],
                stateMutability: 'view',
                type: 'function'
              }
            }
          }
        ],
        '137': [
          {
            name: 'erc20-balance-of',
            params: {
              symbol: 'OCEAN',
              address: '0x282d8efCe846A88B159800bd4130ad77443Fa1A1',
              decimals: 18
            }
          }
        ]
      }
    }
  }
]

export const testStrategies = [
  {
    __typename: 'Strategy',
    name: 'ocean-dao-brightid',
    params: {
      brightIdMultiplier: 4,
      notVerifiedMultiplier: 1,
      brightIdNetwork: '4',
      registry: brightIdContractAddress,
      symbol: 'OCEAN',
      strategies: {
        '4': [
          {
            name: 'erc20-balance-of',
            params: {
              symbol: 'OCEAN',
              address: '0x0a76Cb2aB931B13f6b48E6F24025566E2538822B',
              decimals: 18
            }
          }
        ]
      }
    }
  }
]
