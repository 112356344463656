import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import styles from './GrantCollector.module.css'
import { treasuryABI, treasuryContract, oceanToken } from './contract'
import { useWeb3 } from '../../../../../hooks/useWeb3'
import Section from '../../../../molecules/Section'
import { AbiItem } from 'web3-utils'
import InputWithButton from '../../../../molecules/InputWithButton'

/* eslint-disable no-unused-vars */
enum RequestStatus {
  Success = 'SUCCESS',
  Error = 'ERROR',
  Pending = 'PENDING'
}
/* eslint-disable no-unused-vars */

export default function GrantCollector(props: any) {
  const [rewardString, setRewardString] = useState('')
  const [requestStatus, setRequestStatus] = useState(undefined)
  const [message, setMessage] = useState(props.card.input.description)

  const { web3, walletAddress, isRightNetwork } = useWeb3()

  useEffect(() => {
    if (!walletAddress) {
      setRequestStatus(undefined)
      setMessage(undefined)
      setRewardString('')
    }
  }, [walletAddress])

  const messageStyle = clsx({
    [styles.error]: requestStatus === RequestStatus.Error,
    [styles.success]: requestStatus === RequestStatus.Success,
    [styles.common]: requestStatus === RequestStatus.Pending || !requestStatus
  })

  async function isGrantClaimed(proposalId: number) {
    try {
      const contract = new web3.eth.Contract(
        treasuryABI as AbiItem[],
        treasuryContract
      )
      const tx = await contract.methods.isGrantClaimedId(proposalId).call()
      return tx
    } catch (error) {
      setRequestStatus(RequestStatus.Error)
      setMessage(error.message)
    }
  }

  async function claimGrant() {
    try {
      setRequestStatus(RequestStatus.Pending)
      var json
      try {
        json = JSON.parse(Buffer.from(rewardString, 'base64').toString('utf8'))
      } catch (error) {
        setRequestStatus(RequestStatus.Error)
        setMessage('The provided reward string is wrong')
        return
      }

      const { proposalId } = json
      const isClaimed = await isGrantClaimed(proposalId)
      if (isClaimed) {
        setRequestStatus(RequestStatus.Success)
        setMessage('Your grant was already claimed')
        return
      }

      const { v, r, s } = json

      const contract = new web3.eth.Contract(
        treasuryABI as AbiItem[],
        treasuryContract
      )

      await contract.methods
        .claimGrant(
          json.roundNumber,
          json.recipient,
          json.proposalId,
          json.timeStamp,
          json.amount,
          oceanToken,
          v,
          r,
          s
        )
        .send({ from: walletAddress })

      setRequestStatus(RequestStatus.Success)
      setMessage('Your grant has been successfully claimed')
    } catch (error) {
      setRequestStatus(RequestStatus.Error)
      setMessage(error.message)
    }
  }

  return (
    <Section
      id={props.id}
      title={props.title}
      headerCenter
      description={props.description}
    >
      <div className={styles.grantCollectorContainer}>
        <InputWithButton
          input={{
            type: 'string',
            name: 'rewardString',
            placeholder: props.card.input.placeholder,
            value: rewardString,
            onChange: (input: any) => {
              const newRewardString = input.target.value.trim()
              if (newRewardString === '') {
                setRequestStatus(undefined)
                setMessage(props.card.input.description)
              }
              setRewardString(input.target.value.trim())
            },
            disabled: !walletAddress || !isRightNetwork
          }}
          button={{
            text:
              requestStatus === RequestStatus.Pending
                ? 'Loading...'
                : props.card.action,
            disabled: !walletAddress || !isRightNetwork,
            handleOnClick: () => claimGrant()
          }}
          image={props.card.input.image}
        />
        <div className={styles.errorMessageContainer}>
          <p className={messageStyle}>
            {message || props.card.input.description}
          </p>
        </div>
      </div>
    </Section>
  )
}
