import { AbiItem } from 'web3-utils/types'

export const brightIdContractAddress =
  '0xc37F8341Ac6e4a94538302bCd4d49Cf0852D30C0'

export const brightIdContext =
  '0x4f6365616e44414f000000000000000000000000000000000000000000000000'

export const brightIdABI: AbiItem | AbiItem[] = [
  {
    inputs: [
      { internalType: 'bytes32', name: '_context', type: 'bytes32' },
      { internalType: 'address', name: '_verifier', type: 'address' }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferred',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'context',
        type: 'bytes32'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'verifier',
        type: 'address'
      }
    ],
    name: 'SetBrightIdSettings',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'addr', type: 'address' }
    ],
    name: 'Sponsor',
    type: 'event'
  },
  {
    inputs: [],
    name: 'context',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_user', type: 'address' }],
    name: 'isVerifiedUser',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: '_context', type: 'bytes32' },
      { internalType: 'address[]', name: '_addrs', type: 'address[]' },
      { internalType: 'uint256', name: '_timestamp', type: 'uint256' },
      { internalType: 'uint8', name: '_v', type: 'uint8' },
      { internalType: 'bytes32', name: '_r', type: 'bytes32' },
      { internalType: 'bytes32', name: '_s', type: 'bytes32' }
    ],
    name: 'register',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: '_context', type: 'bytes32' },
      { internalType: 'address', name: '_verifier', type: 'address' }
    ],
    name: 'setSettings',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'addr', type: 'address' }],
    name: 'sponsor',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'verifications',
    outputs: [
      { internalType: 'uint256', name: 'time', type: 'uint256' },
      { internalType: 'bool', name: 'isVerified', type: 'bool' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'verifier',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  }
]
