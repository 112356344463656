import React, { useEffect, useState } from 'react'
import styles from './RegistriesProgress.module.css'
import NumberUnit from './NumberUnit'
import CustomProgressBar from '../atoms/CustomProgressBar'
import { useWeb3 } from '../../hooks/useWeb3'

export default function RegistriesProgress({
  currentBoost,
  maximumBoost,
  votingPowerData,
  votingBoostData,
  delegates,
  scores,
  error,
  isLoading
}: {
  currentBoost: number
  maximumBoost: number
  votingPowerData: any
  votingBoostData: any
  error: Error
  delegates: string[]
  scores: any
  isLoading: boolean
}) {
  const { walletAddress, isRightNetwork } = useWeb3()
  const [votingPower, setVotingPower] = useState('0')
  const [maximumVotingPower, setMaximumVotingPower] = useState('0')
  const [currentBoostPercentage, setCurrentBoostPecentage] = useState(0)
  const maximumBoostPercentage = 400

  const changeMaxVotingPower = (
    newBoostPercentage: number,
    userScore: any,
    maximumPower: number
  ) => {
    setMaximumVotingPower(
      newBoostPercentage === maximumBoostPercentage
        ? userScore.toFixed(2)
        : maximumPower > 0
        ? maximumPower.toFixed(2)
        : maximumPower
    )
  }

  useEffect(() => {
    if (
      !walletAddress ||
      !isRightNetwork ||
      !scores ||
      currentBoost === undefined ||
      isLoading
    ) {
      setVotingPower('0')
      setMaximumVotingPower('0')
      setCurrentBoostPecentage(0)
      return
    }
    let userScore = 0
    delegates.forEach((delegator: any) => {
      if (scores[delegator.delegator]) {
        userScore += scores[delegator.delegator]
      }
    })
    const newBoostPercentage =
      (currentBoost / maximumBoost) * maximumBoostPercentage
    if (newBoostPercentage === maximumBoostPercentage) {
      userScore += scores[walletAddress] / 4
      userScore *= maximumBoost
    } else {
      userScore += scores[walletAddress]
    }
    setVotingPower(userScore > 0 ? userScore.toFixed(2) : '0')
    const maximumPower = userScore * maximumBoost
    setCurrentBoostPecentage(
      (currentBoost / maximumBoost) * maximumBoostPercentage
    )
    if (newBoostPercentage === currentBoostPercentage) {
      maximumVotingPower === '0' &&
        changeMaxVotingPower(newBoostPercentage, userScore, maximumPower)
      return
    }
    changeMaxVotingPower(newBoostPercentage, userScore, maximumPower)
  }, [currentBoost, isRightNetwork, walletAddress, scores])

  return (
    <div
      className={`${styles.registryContainer} ${
        delegates.length === 0 && styles.noButtomBorder
      }`}
    >
      <div className={styles.votingPower}>
        <span className={styles.sectionTitle}>{votingPowerData.title}</span>
        <div className={styles.votingPowerNumbers}>
          <NumberUnit
            label={votingPowerData.currentVotesLabel}
            value={votingPower || '0'}
          />
          <NumberUnit
            label={votingPowerData.maximumVotesLabel}
            value={maximumVotingPower || '0'}
          />
        </div>
      </div>
      <div className={styles.votingBoost}>
        <span className={styles.sectionTitle}>{votingBoostData.title}</span>
        <div className={styles.votingBoostMetrics}>
          <CustomProgressBar completed={currentBoostPercentage} />
        </div>
      </div>
      {error && (
        <p className={styles.error}>
          Server error. Could not fetch voting scores.
        </p>
      )}
    </div>
  )
}
