import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/Layout'
import Graphic from '../components/pages/technology/Marketplaces/Graphic'
import Badge from '../components/atoms/Badge'
import styles from '../components/templates/PageTechnology/index.module.css'
import GrantCollector from '../components/pages/dao/web3Tools/grantCollector/GrantCollector'
import SectionWalletConnection from '../components/pages/dao/web3Tools/SectionWalletConnection'
import BoostVotingPower from '../components/pages/dao/web3Tools/BoostVotingPower'
import Web3Provider from '../hooks/useWeb3'

export default function PageGatsbyWeb3Tools(props: PageProps) {
  const {
    feature,
    badge,
    title,
    actions,
    description,
    claimGrant,
    walletIcon,
    boostVotingPower,

    graphicMarketplace
  } = (props.data as any).web3Tools.edges[0].node.childDaoJson

  return (
    <Web3Provider>
      <Layout
        title={title}
        description={description}
        uri={props.uri}
        location={props.location}
        heroActions={actions}
        heroCenter
        heroLabel={
          badge ? (
            <>
              <Badge className={styles.badge} label={badge} /> {feature}
            </>
          ) : (
            feature
          )
        }
        heroContent={
          graphicMarketplace ? <Graphic content={graphicMarketplace} /> : null
        }
        // heroActions={overview.actions}
        uniformHeroActionButtons
      >
        <SectionWalletConnection walletIcon={walletIcon} />
        <BoostVotingPower {...boostVotingPower} walletIcon={walletIcon} />
        <GrantCollector {...claimGrant} />
      </Layout>
    </Web3Provider>
  )
}

// From pageQuery @ src/components/templates/PageTechnology/index.tsx
export const contentQuery = graphql`
  query Web3ToolsPageQuery {
    web3Tools: allFile(
      filter: { relativePath: { eq: "pages/dao/web3Tools.json" } }
    ) {
      edges {
        node {
          childDaoJson {
            id
            title
            description
            actions {
              title
              url
            }
            walletIcon {
              childImageSharp {
                fluid(maxWidth: 506) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            boostVotingPower {
              title
              id
              description
              votingInformation {
                votingPower {
                  title
                  currentVotesLabel
                  maximumVotesLabel
                  powerDetailsText
                  delegatesText
                  delegatesDescription
                  delegatedPowerText
                  yourPowerText
                }
                votingBoost {
                  title
                }
              }
              registries {
                brightId {
                  name
                  logo {
                    childImageSharp {
                      fluid(maxWidth: 506) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                  boost
                  action
                  description
                }
              }
            }
            claimGrant {
              title
              id
              description
              card {
                title
                action
                description
                input {
                  placeholder
                  description
                  image {
                    childImageSharp {
                      fluid(maxWidth: 506) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
