import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  brightIdABI,
  brightIdContext,
  brightIdContractAddress
} from './contract'
import RegistryCard from '../../../../molecules/RegistryCard'
import { useWeb3 } from '../../../../../hooks/useWeb3'
import { RequestStatus } from '../common'

export async function getSignatureContext(address: string) {
  const resp = await axios.get(`/api/dao/sign/${address}`)
  if (resp && resp?.data && !resp?.data?.error)
    return {
      addresses: resp.data.contextIds,
      signature: resp.data.sig,
      timestamp: resp.data.timestamp
    }
  else return resp
}

export default function BrightId({
  registry,
  changeCurrentBoost
}: {
  registry: any
  changeCurrentBoost: (boost: number) => void
}) {
  const { walletAddress, web3, isRightNetwork } = useWeb3()
  const [disabled, setDisabled] = useState(true)
  const [isVerified, setIsVerified] = useState<boolean>()
  const [brightIdContract, setBrightIdContract] = useState(undefined)
  const [message, setMessage] = useState(undefined)
  const [requestStatus, setRequestStatus] = useState(undefined)
  const [showQRcode, setShowQRcode] = useState(false)
  const [description, setDescription] = useState(undefined)
  const [buttonText, setButtonText] = useState(`EARN ${registry.boost}%`)

  async function isUserVerified() {
    const response = await brightIdContract.methods
      .isVerifiedUser(walletAddress)
      .call()
    return response
  }

  async function handleButtonText() {
    const resp: any = await getSignatureContext(walletAddress)
    if (resp?.data?.error) {
      if (resp.data.errorNum === 4) {
        setDescription(`You don't have a sponsorship on BrightID.`)
        setButtonText('Get sponsorship')
        return
      }
      if (resp.data.errorNum === 2) {
        setDescription(registry.description)
        setButtonText(`EARN ${registry.boost}%`)
        return
      }
    }
    setButtonText(`Register`)
  }

  useEffect(() => {
    if (isVerified === undefined) return
    if (isVerified) {
      setDisabled(true)
      changeCurrentBoost(Number(registry.boost) / 100)
      return
    }
    changeCurrentBoost(0)
  }, [isVerified])

  useEffect(() => {
    if (!walletAddress || !isRightNetwork) {
      setDisabled(true)
      return
    }
    const newBrightIdContract = new web3.eth.Contract(
      brightIdABI,
      brightIdContractAddress
    )
    setBrightIdContract(newBrightIdContract)
    setDisabled(false)
  }, [walletAddress, isRightNetwork])

  useEffect(() => {
    setDescription(registry.description)
  }, [registry])

  async function sposorUser(address: string) {
    setRequestStatus(RequestStatus.Pending)
    try {
      const resp = await axios.get(`/api/dao/sponsorship/${address}`)
      if (resp.data.error !== undefined) {
        setMessage(resp.data.error)
        setRequestStatus(RequestStatus.Error)
        return
      }
      setMessage(`Your account was succesfuly sponsored.`)
      setRequestStatus(RequestStatus.Success)
    } catch (error) {
      setMessage(error.message)
      setRequestStatus(RequestStatus.Error)
    }
  }

  useEffect(() => {
    if (!brightIdContract || !walletAddress || !isRightNetwork) {
      if (isVerified === undefined) return
      setIsVerified(false)
      return
    }
    handleButtonText()
    isUserVerified().then((resp) => {
      setIsVerified(resp)
    })
  }, [brightIdContract, walletAddress, isRightNetwork])

  const onHideQRcode = () => {
    handleButtonText().then(() => {
      setShowQRcode(false)
    })
  }

  async function registerAccount(
    addresses: string,
    signature: any,
    timestamp: string
  ) {
    try {
      setRequestStatus(RequestStatus.Pending)
      await brightIdContract.methods
        .register(
          brightIdContext,
          addresses,
          timestamp,
          signature.v,
          `0x${signature.r}`,
          `0x${signature.s}`
        )
        .send({
          from: walletAddress
        })
      setMessage('You are successfully registered')
      setRequestStatus(RequestStatus.Success)
    } catch (error) {
      setMessage(error.message)
      setRequestStatus(RequestStatus.Error)
    }
  }

  async function onRegister() {
    var resp: any
    resp = await getSignatureContext(walletAddress)
    if (resp?.data?.error) {
      if (resp.data.errorNum === 4) {
        setDescription(
          'We are creating your subscription, refresh the page after 30 second.'
        )
        await sposorUser(walletAddress)
        return
      }
      if (resp.data.errorNum === 2) {
        setDescription(
          'Link your BrightID to OceanDAO to begin earning rewards.'
        )
        setShowQRcode(true)
        return
      }
      setMessage(resp.data.errorMessage)
      setRequestStatus(RequestStatus.Error)
    }

    await registerAccount(resp.addresses, resp.signature, resp.timestamp)
    const newVerify = await isUserVerified()
    newVerify && setIsVerified(newVerify)
  }

  return (
    <RegistryCard
      {...registry}
      address={walletAddress}
      disabled={disabled}
      description={description}
      isVerified={isVerified}
      register={onRegister}
      message={message}
      requestStatus={requestStatus}
      showQRcode={showQRcode}
      onHideQRcode={onHideQRcode}
      buttonText={buttonText}
    />
  )
}
